<template>
  <div
    class="team-scroller-team"
    @mouseleave="onMouseLeave"
    @mouseenter="onMouseEnter"
  >
    <TeamTag
      v-if="!tagHidden"
      mode="explain"
      :team="team"
      :submittedIndictor="minified"
      class="team-scroller-team__tag"
    />
    <transition name="team-scroller-team__transition" mode="out-in">
      <div class="team-scroller-team__users" v-if="!minified">
        <TeamScrollerTeamLabel
          v-if="team.label"
          :value="team.label"
          class="team-scroller-team__label"
        />
        <AppUserCard
          v-for="user in users"
          class="team-scroller-team__user"
          :key="user.id"
          :user="user"
          :style="{ width: round ? 152 : 142 }"
          :rounded="round"
          :submitted="user.submitted"
          selectable
          :mediaDisabled="isUprPlayMode"
          @dblclick="toggleScribe(user)"
        >
          <UnconditionalPositiveRegardStage
            v-if="isUprPlayMode"
            :userID="user.id"
            encrypted
          />
        </AppUserCard>
      </div>
    </transition>
    <SvgIcon
      name="chevron-left-regular"
      v-if="!disabledLeftArrow && scrollable"
      size="large"
      class="team-scroller__arrow team-scroller__arrow--left"
      @click="decrement"
    />
    <SvgIcon
      name="chevron-right-regular"
      v-if="!disabledRightArrow && scrollable"
      size="large"
      class="team-scroller__arrow team-scroller__arrow--right"
      @click="increment"
    />
  </div>
</template>

<script>
import { toRefs, computed } from "@vue/composition-api"
import _ from "lodash"

import Mode from "@shared/enums/Mode"
import MissionType from "@shared/enums/Mission"
import User from "@shared/User"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import TeamTag from "@/components/GroupTeams/Common/TeamTag"
import UserService from "@/services/user.service"
import TeamScrollerTeamLabel from "@/components/Main/TeamScrollerTeamLabel"
import useTeamScroller from "@/components/Main/useTeamScroller"

import useStore from "@/use/useStore"

export default {
  name: "TeamScrollerTeam",
  components: {
    AppUserCard,
    TeamTag,
    TeamScrollerTeamLabel,
    UnconditionalPositiveRegardStage: () =>
      import(
        "@/components/GroupTeams/Common/Games/UnconditionalPositiveRegard/UnconditionalPositiveRegardStage.vue"
      )
  },
  props: {
    team: Object,
    round: {
      type: Boolean,
      default: false
    },
    minified: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    tagHidden: {
      type: Boolean,
      default: false
    },
    maxUsers: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const { team, maxUsers, scrollable } = toRefs(props)
    const { store } = useStore()
    const gameMode = computed(() => store.getters.getCurrentMode)
    const mission = computed(() => store.getters.getCurrentMission)
    const teamUsers = computed(
      () => store.getters.onlineUsersGroupedByTeam?.[team?.value?.id]
    )

    const plays = computed(() => store.getters.missionPlaysArray)

    const disableAutoScroll = computed(() => !scrollable.value)

    const {
      index,
      disabledLeftArrow,
      disabledRightArrow,
      increment,
      decrement,
      onMouseLeave,
      onMouseEnter
    } = useTeamScroller(teamUsers, maxUsers, disableAutoScroll)

    const users = computed(() => {
      const isUserMutedByRoomLogic =
        store.getters["group/isUserMutedByRoomLogic"]

      const scribes = store.state.JeopardAi.scribes ?? []

      const values =
        mission.value.behavior !== MissionType.JeopardAI
          ? teamUsers.value
          : teamUsers.value.map(user => {
              const selected = scribes.some(({ id }) => id === user.id)
              return {
                ...user,
                selected: user.selected,
                jeopardai: selected
              }
            })

      const isUserSelected = user =>
        User.isScribe(user) || user.jeopardai === true

      const filtered = _.chain(values)
        .filter(user => User.isPlayer(user) && User.isPlaying(user))
        .sortBy(
          user => User.isMuted(user) || Boolean(isUserMutedByRoomLogic(user))
        )
        .sortBy(user => !isUserSelected(user))
        .map(user => {
          return {
            ...user,
            submitted: plays.value.some(play => play.userID === user.id)
          }
        })
        .value()

      return filtered.splice(
        index.value,
        Math.min(maxUsers.value, filtered.length)
      )
    })

    const isUprPlayMode = computed(
      () =>
        gameMode.value === Mode.Play &&
        mission.value.behavior === MissionType.UnconditionalPositiveRegard
    )

    return {
      isUprPlayMode,
      users,
      disabledLeftArrow,
      disabledRightArrow,
      increment,
      decrement,
      onMouseLeave,
      onMouseEnter
    }
  },
  methods: {
    toggleScribe(user) {
      if (!this.$store.getters["group/isViewerHostLike"]) return
      UserService.updateScribe(user, !User.isScribe(user))
    }
  }
}
</script>

<style lang="scss">
.team-scroller-team {
  display: flex;
  flex-direction: column;
  position: relative;

  &__label {
    left: 0;
    position: absolute;
  }

  &__tag {
    height: 35px;
    align-self: center;
  }

  &__users {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__user {
    width: 140px;
    height: 140px;
  }

  &__user:not(:last-child) {
    margin-right: 10px;
  }

  &__transition-enter-active {
    transition: all ease 0.5s 0.8s;
    opacity: 1;
    transform: scale(1);
  }

  &__transition-leave-active {
    transition: all ease 0.5s;
    opacity: 1;
    transform: scale(1);
  }

  &__transition-enter,
  &__transition-leave-to {
    opacity: 0;
    transform: scale(0.5);
  }
}
</style>
