var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "team-scroller",
      class:
        ((_obj = {}),
        (_obj["team-scroller--minified"] = _vm.minified),
        (_obj["team-scroller--condensed"] = _vm.condensed),
        _obj),
      on: { mouseleave: _vm.onMouseLeave, mouseenter: _vm.onMouseEnter },
    },
    [
      _vm.label
        ? _c("TeamScrollerTeamLabel", {
            staticClass: "team-scroller__label",
            attrs: { value: _vm.label },
          })
        : _vm._e(),
      _vm._l(_vm.filteredTeams, function (team, i) {
        return _c("TeamScrollerTeam", {
          key: team.id + "-" + i,
          staticClass: "team-scroller__team-scoller-team",
          attrs: {
            team: team,
            minified: _vm.minified,
            maxUsers: _vm.maxUsers,
            round: _vm.round,
            scrollable: _vm.isUserScroller,
            tagHidden: _vm.isTagsHidden,
          },
        })
      }),
      !_vm.disabledLeftArrow
        ? _c("SvgIcon", {
            staticClass: "team-scroller__arrow team-scroller__arrow--left",
            attrs: { name: "chevron-left-regular", size: "large" },
            on: { click: _vm.decrement },
          })
        : _vm._e(),
      !_vm.disabledRightArrow
        ? _c("SvgIcon", {
            staticClass: "team-scroller__arrow team-scroller__arrow--right",
            attrs: { name: "chevron-right-regular", size: "large" },
            on: { click: _vm.increment },
          })
        : _vm._e(),
      _c("ResizeObserver", { on: { notify: _vm.handleResize } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }