var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "team-scroller-team",
      on: { mouseleave: _vm.onMouseLeave, mouseenter: _vm.onMouseEnter },
    },
    [
      !_vm.tagHidden
        ? _c("TeamTag", {
            staticClass: "team-scroller-team__tag",
            attrs: {
              mode: "explain",
              team: _vm.team,
              submittedIndictor: _vm.minified,
            },
          })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "team-scroller-team__transition", mode: "out-in" } },
        [
          !_vm.minified
            ? _c(
                "div",
                { staticClass: "team-scroller-team__users" },
                [
                  _vm.team.label
                    ? _c("TeamScrollerTeamLabel", {
                        staticClass: "team-scroller-team__label",
                        attrs: { value: _vm.team.label },
                      })
                    : _vm._e(),
                  _vm._l(_vm.users, function (user) {
                    return _c(
                      "AppUserCard",
                      {
                        key: user.id,
                        staticClass: "team-scroller-team__user",
                        style: { width: _vm.round ? 152 : 142 },
                        attrs: {
                          user: user,
                          rounded: _vm.round,
                          submitted: user.submitted,
                          selectable: "",
                          mediaDisabled: _vm.isUprPlayMode,
                        },
                        on: {
                          dblclick: function ($event) {
                            return _vm.toggleScribe(user)
                          },
                        },
                      },
                      [
                        _vm.isUprPlayMode
                          ? _c("UnconditionalPositiveRegardStage", {
                              attrs: { userID: user.id, encrypted: "" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      !_vm.disabledLeftArrow && _vm.scrollable
        ? _c("SvgIcon", {
            staticClass: "team-scroller__arrow team-scroller__arrow--left",
            attrs: { name: "chevron-left-regular", size: "large" },
            on: { click: _vm.decrement },
          })
        : _vm._e(),
      !_vm.disabledRightArrow && _vm.scrollable
        ? _c("SvgIcon", {
            staticClass: "team-scroller__arrow team-scroller__arrow--right",
            attrs: { name: "chevron-right-regular", size: "large" },
            on: { click: _vm.increment },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }