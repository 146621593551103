<template>
  <div
    class="team-scroller"
    :class="{
      ['team-scroller--minified']: minified,
      ['team-scroller--condensed']: condensed
    }"
    @mouseleave="onMouseLeave"
    @mouseenter="onMouseEnter"
  >
    <TeamScrollerTeamLabel
      v-if="label"
      :value="label"
      class="team-scroller__label"
    />
    <TeamScrollerTeam
      v-for="(team, i) in filteredTeams"
      :key="`${team.id}-${i}`"
      :team="team"
      :minified="minified"
      :maxUsers="maxUsers"
      :round="round"
      :scrollable="isUserScroller"
      :tagHidden="isTagsHidden"
      class="team-scroller__team-scoller-team"
    />
    <SvgIcon
      name="chevron-left-regular"
      v-if="!disabledLeftArrow"
      size="large"
      class="team-scroller__arrow team-scroller__arrow--left"
      @click="decrement"
    />
    <SvgIcon
      name="chevron-right-regular"
      v-if="!disabledRightArrow"
      size="large"
      class="team-scroller__arrow team-scroller__arrow--right"
      @click="increment"
    />
    <ResizeObserver @notify="handleResize" />
  </div>
</template>

<script>
import { ResizeObserver } from "vue-resize"
import { debounce } from "lodash"
import { toRefs, computed } from "@vue/composition-api"

import TeamScrollerTeam from "@/components/Main/TeamScrollerTeam"
import TeamScrollerTeamLabel from "@/components/Main/TeamScrollerTeamLabel"

import useTeamScroller from "@/components/Main/useTeamScroller"

export default {
  name: "TeamScroller",
  components: {
    ResizeObserver,
    TeamScrollerTeam,
    TeamScrollerTeamLabel
  },
  props: {
    label: {
      type: String,
      default: undefined
    },
    round: {
      type: Boolean,
      default: false
    },
    itemSize: {
      default: 400,
      type: Number
    },
    minified: {
      type: Boolean,
      default: false
    },
    maxUsersPerTeam: {
      type: Number,
      default: 1
    },
    maxTeams: {
      type: Number,
      default: 5
    },
    teams: {
      type: Array,
      default: () => []
    },
    condensed: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { teams, maxTeams, label } = toRefs(props)

    const teamsWithLeadLabel = computed(() => {
      if (label.value) return teams.value

      const labels = {}

      return teams.value.map(team => {
        if (labels[team.location]) return team
        labels[team.location] = true
        return { ...team, label: team.location }
      })
    })

    const {
      index,
      disabledLeftArrow,
      disabledRightArrow,
      increment,
      decrement,
      onMouseLeave,
      onMouseEnter
    } = useTeamScroller(teamsWithLeadLabel, maxTeams)

    return {
      index,
      disabledLeftArrow,
      disabledRightArrow,
      increment,
      decrement,
      onMouseLeave,
      teamsWithLeadLabel,
      onMouseEnter
    }
  },
  data() {
    return {
      componetWidth: null
    }
  },
  computed: {
    isUserScroller() {
      return this.filteredTeams.length === 1
    },
    maxUsers() {
      const maxItemsInRow = Math.floor(this.componetWidth / 160) || 1

      const maxUsersInTeam = Math.max(
        Math.floor(maxItemsInRow / this.teams.length),
        1
      )

      return this.isUserScroller ? maxUsersInTeam : 1
    },
    hasBreakouts() {
      return this.filteredTeams.some(({ show, breakout }) => breakout && show)
    },
    isTagsHidden() {
      return this.$store.getters.game?.hideTeamTags && !this.hasBreakouts
    },
    indexComputed() {
      return Math.min(this.index, this.teamsWithLeadLabel.length - 1)
    },
    filteredTeams() {
      return this.teamsWithLeadLabel
        .slice(this.indexComputed, this.indexComputed + this.maxTeams)
        .filter(Boolean)
    }
  },
  mounted() {
    this.componetWidth = this.$el.offsetWidth
  },
  methods: {
    handleResize: debounce(function ({ width }) {
      this.componetWidth = width
    }, 500)
  }
}
</script>

<style lang="scss">
.team-scroller {
  $block: &;

  height: 200px;
  overflow: hidden;
  transition: height 1.5s;
  transform: translateZ(0);
  position: relative;
  display: flex;
  justify-content: center;

  &__label {
    left: 0;
    position: absolute;
  }

  &__arrow {
    position: absolute;
    font-size: 30px;
    cursor: pointer;
    top: 58%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.8) !important;
    z-index: 10;
    &--right {
      right: 20px;
    }
    &--left {
      left: 20px;
    }
  }

  &--minified {
    height: 40px;
    .team-scroller__arrow {
      top: 46%;
    }
  }

  &__team-scoller-team {
    height: 100%;
    display: flex;
    flex: 1;
  }

  &--condensed {
    justify-content: center;
    #{$block}__team-scoller-team {
      flex: initial;
      flex-basis: 230px;
    }
  }
}
</style>
